import React from "react";
import "./installedsection.css";
import icon1 from "../../../assets/icon1.svg";
import icon2 from "../../../assets/icon2.svg";
import icon3 from "../../../assets/icon3.svg";

export default function InstalledSection() {
  let boxData = [
    {
      iconName: icon3,
      text: "100+",
      extraContent: "Chimney Sweep",
    },
    {
      iconName: icon2,
      text: "200+",
      extraContent: "Installations",
    },
    {
      iconName: icon1,
      text: "1000+",
      extraContent: "Satisfied Customers",
    },
  ];
  return (
    <div className="bg_installed_container">
      <div className="same_width_container">
        <h6 className="title_installed_text">
          Till Date we have
          <br />
          <span>Installed</span>
        </h6>

        <div className="three_box_container">
          {boxData?.map((item, index) => {
            return (
              <div className="installed_box_container" key={index}>
                <div className="installed_icon_container">
                  <img src={item?.iconName} alt="imgIcon" />
                </div>
                <p>{item?.text}</p>
                <p>{item?.extraContent}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
