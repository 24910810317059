import React, { Fragment } from 'react';
import './header.css';
import callIcon from '../../assets/callicon.png';
import facebookIcon from '../../assets/fbYellow.svg';
import linkedinIcon from '../../assets/linkdinYello.svg';
import instagramIcon from '../../assets/instaYello.svg';
import locationIcon from '../../assets/locationYellow.svg';

const HeaderSection = () => {

    const Icon = ({ icon, index ,link}) => {
        return (
            <a href={link} target="_blank" rel="noreferrer">
            <img src={icon} key={index} className='icon_size' />
            </a>
        )
    }

    return (
        <header className="main_container">
            <div className='main_body_container same_width_container'>
                <div className='left_content'>
                    <Icon icon={callIcon} />
                    <p className='info_text'>Get quick appointment and technical support:<span
                        className='font-bold text-color-orange'
                    > 780-986-1181</span>
                    </p>
                </div>
                <div className='right_content' >
                    <div className='flex gap-2 items-center'>
                        <img src={locationIcon} className='icon_size' />
                        <p className='info_text'>#4 5907-45 Street Leduc, AB T9E 7B3, Canada</p>
                    </div>
                    {[{icon:facebookIcon , link:"https://www.facebook.com/leducchimney/"}, {icon:instagramIcon,link:"https://www.instagram.com/leducchimneysweep/"}].map((icon, index) => {
                        return (
                            <Fragment key={index}>
                                <div className='divider'></div>
                                <Icon icon={icon.icon} link={icon.link} />
                            </Fragment>
                        )
                    }
                    )}
                </div>
            </div>
        </header>
    );
}

export default HeaderSection;