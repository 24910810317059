import React, { useState ,useEffect} from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/logoGroup.svg";
import smallLogo from "../../assets/leduclogo1.png";
import "./navbar.css";
import { MdClose, MdMenu } from "react-icons/md";

export default function Navbar() {
  const [openMenu, setOpenMenu] = useState(false);
  const [services, setServices] = useState(false);
  const [supplier, setSupplier] = useState(false)
  const [contact, setContact] = useState(false)
  const [isMobile, setIsMobile] = useState(false);


  const navData = [
    { name: "Chimney Sweep", navigate: "/ServicePage" },
    { name: "WETT Inspection", navigate: "/ServicePageWetInsception" },
    { name: "Supplies and Products", navigate: "" },
    { name: "Installations", navigate: "/ServicePageInstallation" },
    { name: "Masonry Repairs", navigate: "/ServicePageMassonary" }
  ];
  const contactData = [
    { name: "Contact us", navigate: "/ContactUsPage" },
    { name: "FAQ", navigate: "/Faq" },
    { name: "Gallery", navigate: "/OurGallary" },

  ];


  const checkMobileSize = () => {
    setIsMobile(window.innerWidth > 767); // Adjust the threshold as needed
  };


  useEffect(() => {
    checkMobileSize(); // Initial setup

    // Event listener for window resize
    const handleResize = () => {
      checkMobileSize(); // Update state on resize
    };

    // Attach event listener when component mounts
    window.addEventListener("resize", handleResize);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  function handleService() {

    setServices(true)
    setContact(false)
  }
  function handleContact() {
    setContact(!contact);
    setServices(false);
    setSupplier(false);
  }
  const style = supplier 
    ? 
     { left: "-12rem", top: "6rem" } :{ left: "0rem", top: "0rem" } 



  const suppliersData = [{ name: "Wood", navigate: "/ServicePageWood" }, { name: "Electric", navigate: "/ServicePageElectric" }, { name: "Gas", navigate: "/ServicePageGas" }, { name: "Pellet", navigate: "/ServicePagePellet" }];
  return (
    <nav className="nav_main_container">
      <div className="logo_menu_btn_container">
        <button
          className="menu_icon_container"
          onClick={() => setOpenMenu(true)}
        >
          <MdMenu />
        </button>
        <div className="nav_logo_container">
          <img src={isMobile ? logo : smallLogo} alt="Logo" className="hero_logo" />
        </div>
      </div>
      <ul className="hero_nav" style={{ left: !openMenu ? "-100%" : 0 }}>
        <button
          className="md_close_container"
          onClick={() => setOpenMenu(false)}
        >
          <MdClose />
        </button>
        <li className="nav_dropdown_container">
          <NavLink to="/" className="nav_item_text">
            Home
          </NavLink>
        </li>
        <li className="nav_dropdown_container">
          <NavLink to="/Sales" className="nav_item_text">
          Sales
          </NavLink>
        </li>
        <li className="nav_dropdown_container">
          <NavLink to="/AboutUs" className="nav_item_text">
            About Us
          </NavLink>
        </li>
        <li className="nav_dropdown_container" onClick={handleService}>
          <NavLink className="nav_item_text">
            {/* <NavLink to="/ServicePage" className="nav_item_text"> */}
            Our Services
          </NavLink>

          <div className="nav_sub_links_container" style={{top:contact ? "1rem" : "2rem"}}>
            {services && navData?.map((item, index) => {
              return (
                <div key={index} onClick={() => {

                  if (item?.name === "Supplies and Products") {

                    setSupplier(true)

                  }
                }}>
                  <Link to={item?.navigate}>{item.name}</Link>

                  {supplier && <div className="nav_sub_links_container1" style={style}>
                    {suppliersData?.map((item, index) => {
                      return (
                        <div key={index} >
                          <Link to={item?.navigate} >{item?.name}</Link>
                        </div>
                      );
                    })}
                  </div>}
                </div>
              );
            })}
          </div>
        </li>

        <li className="nav_dropdown_container" onClick={handleContact}>
          {/* <NavLink to="/ContactUsPage" className="nav_item_text"> */}
          <NavLink className="nav_item_text">
            Contact Us
          </NavLink>

       

          <div className='nav_sub_links_container' style={{top:!contact ? "6rem" : "2rem"}}>
         
            {contact && contactData?.map((item, index) => {
              return (
                <div key={index} >
                  <Link to={item?.navigate}>{item.name}</Link>


                </div>
              );
            })}
          </div>
        </li>
        <li>
        <NavLink className="nav_item_text" to="/ouropenings">
            Our Openings
          </NavLink>
        </li>
        <Link  to='/ContactUspage'>        <button>Book your appointment</button>
        </Link>
      

      </ul>
    </nav>
  );
}
