import React from "react";
import Navbar from "../Navbar";

import "./commonHeroSection.css";

export default function CommonHeroSection(props) {
  return (
    <div
      className="hero_section"
      style={{ backgroundImage: `url(${props.imgName})` }}
    >
      <div
        className="hero_header same_width_container "
        style={{ margin: "0 auto" }}
      >
        <Navbar />
      </div>

      <div className="main_title_hero_section_container">
        <h6>{props.titleHead}</h6>
        <p>{props.extraContent}</p>
      </div>
    </div>
  );
}
