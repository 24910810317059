import React, { useEffect, useState } from "react";
import "./whereItStartedSection.css";
import bgImg from '../../../assets/whereItAllBg.png'
import bgImg1 from '../../../assets/about2.png'

import profileImg from '../../../assets/profile_pic_2.png'

export default function WhereItStartedSection() {
  const [isMobile, setIsMobile] = useState(false);

  const checkMobileSize = () => {
    setIsMobile(window.innerWidth > 767); // Adjust the threshold as needed
  };


  useEffect(() => {
    checkMobileSize(); // Initial setup

    // Event listener for window resize
    const handleResize = () => {
      checkMobileSize(); // Update state on resize
    };

    // Attach event listener when component mounts
    window.addEventListener("resize", handleResize);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  return (
    <div>
      <div className="same_width_container">
        <div className="started_wrapper">
          <div className="left_width_started_container">
            <div className="img_stated_wrapper">
              <img src={!isMobile ? bgImg1 : bgImg} alt="bgImg" loading="lazy" />
            </div>
          </div>
          <div className="right_width_started_container">
            <h6>Where it all</h6>
            <p>started</p>

            <p>
              Established in 2001, Leduc Chimney Sweep Ltd. a.k.a The Fireplace
              Store is located in Leduc, Alberta, Canada. Since 2 decades now we have
              been serving our prestigious customers with services like Chimney
              Sweeping, WETT Inspections, Supplies to maintain and Clean
              Firestoves and Fireplaces, Fireplace and Chimney Installation. At
              Leduc Chimney Sweep Ltd, we also assist our customers with repair
              and maintenance for both Chimney and Fireplace as well as Masonry
              work.{" "}
            </p>

             {/*<div className="profile_text_container">
              <div className="profile_stated_img_container">
                <img src={profileImg} alt="profile_img" loading="lazy" />
              </div>
              <div className="title_subtitle_container">
                <p>Jessica Brown</p>
                <p>Founder of Comapny</p>
              </div> 
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
}
