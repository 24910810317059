import React from "react";
import purposeimage from "../../../assets/purposeimage.png";
import "./purposeOfSweep.css";

const PurposeOfSweep = () => {
  const serviceInfo = {
    title: "Why Leduc Chimney Sweeps??",
    description: `Our team of experts are WETT Level 3 Certified which 
    makes our team industry experts. Through careful 
    inspections, the team gathers all the preliminary
    information required to install and repair the fireplace 
    and chimney after which they give expert advice 
    regarding the installation/Maintenance along with 
    timelines of the whole process.`,
  };
  return (
    <div className="purpose_container">
      <div className="purpose_content">
        <h6>
          <span>W</span>hy <span>L</span>educ {" "}
        </h6>
        <p>Chimney Sweep Ltd??</p>

        <p>
          Our team of experts are WETT Level 3 Certified which makes our team
          industry experts. Through careful inspections, the team gathers all
          the preliminary information required to install and repair the
          fireplace and chimney after which they give expert advice regarding
          the installation/Maintenance along with timelines of the whole
          process.
        </p>
      </div>
    </div>
  );
};

export default PurposeOfSweep;
