import { useRef, useState } from "react";
import React from "react";
import emailjs from '@emailjs/browser';


export default function ContactUsSales() {

  const [loading, setLoading] = useState(false)
  const [successResponse, setSuccessResponse] = useState(null)

  const form = useRef()


  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    city: '',
    cellno: '',
    address: '',
    subject: '',
    postalcode: '',
    message: '',
  });

  const [error, setError] = useState({
    firstname: '',
    lastname: '',
    email: '',
    city: '',
    cellno: '',
    address: '',
    subject: '',
    postalcode: '',
    message: '',
  });


  const handleSubmit = async (e) => {
    e.preventDefault();

    //add validation of all field
    if (!formData.firstname) {
      setError({ ...error, firstname: 'Please enter your first name' })
    }
    if (!formData.lastname) {
      setError({ ...error, lastname: 'Please enter your last name' })
    }
    if (!formData.email) {
      setError({ ...error, email: 'Please enter your email' })
    }
    if (!formData.city) {
      setError({ ...error, city: 'Please enter your city' })
    }
    if (!formData.cellno) {
      setError({ ...error, cellno: 'Please enter your cell no' })
    }
    if (!formData.address) {
      setError({ ...error, address: 'Please enter your address' })
    }
    if (!formData.subject) {
      setError({ ...error, subject: 'Please enter your subject' })
    }
    if (!formData.postalcode) {
      setError({ ...error, postalcode: 'Please enter your postal code' })
    }
    if (!formData.message) {
      setError({ ...error, message: 'Please enter your message' })
    }
    if (formData.firstname && formData.lastname && formData.email && formData.city && formData.cellno && formData.address && formData.subject && formData.postalcode && formData.message) {
      setError({
        firstname: '',
        lastname: '',
        email: '',
        city: '',
        cellno: '',
        address: '',
        subject: '',
        postalcode: '',
        message: '',
      })

      setLoading(true)
      const formData = new FormData(e.target);

      formData.append("access_key", "a8aa2698-fa9a-4324-9015-1bc3936ea2fa");
  
      const object = Object.fromEntries(formData);
      const json = JSON.stringify(object);
  
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: json
      }).then((res) => res.json());
  
      if (res.success) {
        console.log("Success", res);
        setLoading(false)
         setSuccessResponse(res.success)
      }


      // emailjs
      // .sendForm('service_0ufixws', 'template_wgbu5xb', form.current, {
      //   publicKey: '0N6_GrPf8l9lIN5sn',
      // })
      // .then(
      //   (res) => {
      //     console.log('SUCCESS!',res.status);
      //     
      //   },
      //   (error) => {
      //     console.log('FAILED...', error.text);
      //     setLoading(false)
      //   },
      // );

      // try {
      //   const response = await axios.post('http://127.0.0.1:5000/api/users', formData);
      //   console.log('User created:', response.data);
      //   // Clear form or show success message as needed
      // } catch (error) {
      //   console.error('Error creating user:', error);
      //   // Handle error, show error message
      // }
    }




  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };



  return (
    <div className="contact_us_sales_container">
      <div className="same_width_container">
        <div className="contact_us_card_container">
          <h6>
            Contact <span>Us</span>
          </h6>

          <div className="contact_us_inputs_container">
          <form className="all_inputs_container text-white" ref={form} style={{width : "100%"}} onSubmit={handleSubmit}>
            <div className="inputs_wrapper">

              <input type="text" name="firstname" placeholder="First Name" onChange={handleChange}/>
              <input type="text" name="lastname" placeholder="Last Name" onChange={handleChange}/>
              <input type="number"  name="cellno" placeholder="Cell No" onChange={handleChange}/>
              <input type="email" name="email" placeholder="Email" onChange={handleChange}/>
              <input type="text" name='city' placeholder="City" onChange={handleChange}/>
              <input type="text" name="address" placeholder="Address" onChange={handleChange}/>
              <input type="text" name="subject" placeholder="Subject" onChange={handleChange}/>
              <input type="number" name="postalcode" placeholder="Postal Code" onChange={handleChange}/>
              <select name="Where did you hear about Us?" className="selectBox salesSelect"  onChange={handleChange}>
  <option value="">Where did you hear about Us?</option>
  <option value="Newspaper Advertisement ">Newspaper Advertisement </option>
  <option value="Social media (Instagram/Facebook)">Social media (Instagram/Facebook)</option>
  <option value="Google/Internet">Google/Internet</option>
  <option value="Friends/Family">Friends/Family</option>
</select>
            </div>
            <textarea name="message" placeholder="Message" onChange={handleChange}></textarea>

            <div className="sendBtnDiv" style={{alignItems:"center"}}>
          <button >{loading ? "sending..." : "Submit"}</button>
          <p style={{color : "black"}} className="successMessage">{successResponse ?"You Query has been Sent"   :null }</p>
          </div> 
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
