// add list of data

export const listOfFaq  = [
    {
    question: "How do I know my Chimney needs cleaning?",
    answer: ` If you notice a soot build up, foul odours, smoke backing up, difficulty starting fires,
 visible debris, or nesting materials. It’s time to get the chimney cleaned.
`,
    },
    {
    question: "What happens if you don’t sweep your chimney?",
    answer: `Neglecting Chimney cleaning can cause Chimney fires, Poor indoor air quality, 
Damage to chimney structure, increased risk of Carbon Monoxide poisoning, 
Nesting Animals and Pets and Decreased heating efficiency.`,
    },
    {
    question: "How often should a chimney need cleaning?",
    answer: `We recommend getting it cleaned annually. The frequency of chimney cleaning 
may also depend on how often you use your fireplace or stove. If you use it heavily
 throughout the heating season, more frequent cleaning may be necessary to prevent 
creosote build-up and maintain safety.
`,
    },
    {
    question: "How to prepare for your Chimney Sweep appointment?",
    answer: `For Chimney Sweep, please don’t have the fire on for at least 48 hours prior 
to the appointment date. - Remove any personal items from the hearth and put
 away any pets prior to our arrival. - Our technicians carry portable debit machines 
so you can pay either by debit or credit card right on the site and 
we also accept e-transfer, cash, and cheque.
`,
    },
    {
        question: " How can a sweep help me to maintain a safe home?",
        answer: `Chimney sweep helps in Preventing Chimney Fires, Removing Blockages and Debris, 
    Identifying Structural Issues, Detecting Carbon Monoxide Leaks, Providing
    `,
        },
];


export const listOfFaqChimneySweep  = [
    {
    question: "Why to get your chimney Swept?",
    answer: ` You need to clean your chimney to prevent chimney fires and
 ensure efficient burning. Soot buildup is flammable, and a clean
 chimney allows proper airflow for optimal heat and safety.
`,
    },
    {
    question: "When to get your Chimney Swept?",
    answer: `If you notice a soot build up, foul odors, smoke backing up, difficulty starting fires,  visible debris, or nesting materials. 
It’s time to get the chimney cleaned`,
    },
    {
    question: "How to Prepare your chimney for sweeping?",
    answer: `Before your appointment, let your fireplace cool completely for at least 48 hours to avoid any safety hazards. Additionally, kindly remove any ornaments or decorations from the hearth area to provide the sweep with clear access for cleaning.
`,
    },
    {
    question: "What Happens when your chimney is not cleaned?",
    answer: `Neglect your chimney sweep and face potential disaster! Soot buildup creates a fire hazard, while clogged flues trap smoke and hazardous gasses like carbon monoxide in your home. Avoid these risks with regular cleaning. 
`,
    },
   
];

export const listOfFaqWett  = [
    {
    question: "Why get WETT Certified Inspection?",
    answer: ` WETT Inspections offer unmatched safety expertise for your fireplace. Trained
 inspectors ensure everything is up to code, unlike generic sweeps. Plus,
 WETT certification is often required for home insurance. Enjoy winter nights
 worry-free with a WETT Inspection
`,
    },
    {
    question: "When to get your WETT Inspection done?",
    answer: `Aim for a WETT inspection in spring or early summer!
 It's the perfect prep for winter, allows thorough checks while the
 fireplace is cool, and avoids the busy fall season.`,
    },
   
    {
    question: "How long is the WETT Inspection Valid for?",
    answer: `Aim for a WETT inspection in spring or early summer!
 It's the perfect prep for winter, allows thorough checks while the
 fireplace is cool, and avoids the busy fall season.
`,
    },
    {
        question: " What are different levels of WETT Inspection?",
        answer: `WETT Inspections offer a snapshot of your fireplace's safety at
 that moment, not an expiry date. Regular maintenance and 
chimney sweeps are key. Consider annual WETT inspections 
for peace of mind.
    `,
        },
];


export const listOfFaqInstallation  = [
    {
    question: "What is the process of Installation?",
    answer: `After you have booked an appointment with us our team of experts 
will visit the site for inspection and 
`,
    },
    {
    question: "When is the correct time for Installation ofOutdoorFireplace?",
    answer: `Ideally, we suggest that the outdoor fireplace should be installed 
during the fall season as extreme weather conditions make it difficult
to install the fireplace outside.`,
    },
    {
    question: "How do you ensure safety?",
    answer: `Ideally, we suggest that the outdoor fireplace should be installed 
during the fall season as extreme weather conditions make it difficult
to install the fireplace outside.
`,
    },
    {
    question: "WHY Choose Leduc Chimney Sweep Ltd.?",
    answer: `The expert team of Leduc Chimney Sweep Ltd. carefully inspect the
location and condition of place of installation and then provide
feedback for efficient installation and functioning of fireplaces.
`,
    },
 
];


export const listOfFaqMassonary  = [
    {
        question: "What is Masonry Work?",
        answer: `Masonry work is the skilled craft of constructing structures using bricks, stones,
        concrete blocks, or similar units. These units are typically laid in horizontal 
       courses and bound together with mortar, a paste that hardens over time.`,
    },
    {
        question: "When is the correct time for masonry work?",
        answer: `You need to clean your chimney to prevent chimney fires and ensure efficient 
              burning. Soot buildup is flammable, and a clean chimney allows proper airflow 
              for optimal heat and safety.`,
    },
    {
        question: "HOW do we choose the material for Masonry work?",
        answer: `You need to clean your chimney to prevent chimney fires and ensure efficient 
              burning. Soot buildup is flammable, and a clean chimney allows proper airflow 
              for optimal heat and safety.`,
    },
    {
        question: "WHY is masonry work needed?",
        answer: `You need to clean your chimney to prevent chimney fires and ensure efficient 
              burning. Soot buildup is flammable, and a clean chimney allows proper airflow 
              for optimal heat and safety.`,
    },
 
];