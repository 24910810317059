import React, { useState } from "react";
import "./faqssection.css";
import { MdExpandLess, MdExpandMore } from "react-icons/md";

export default function FAQsSectionction(props) {

  const [openIndex, setOpenIndex] = useState(0);

  const handleOpenExpand = (index) => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div className="faq_section_container">
      <div className="same_width_container">
        <div className="faq_container">
          <div className="faq_left_container">
            <h6>
             {props.firstSentence}
              <br /> <span>{props.secSentence}</span>{props.thirdSentence}
            </h6>

            <p>Frequently Asked Questions</p>

            {props.ExpandDataAll?.map((item, index) => {
              const isOpen = index === openIndex;
              return (
                <div key={index} className="up_down_expand_container">
                  <div
                    className="faq_up_container"
                    onClick={() => handleOpenExpand(index)}
                  >
                    <p>{item?.question}</p>
                    {isOpen ? <MdExpandLess /> :  <MdExpandMore />}

                  </div>
                  {isOpen && (
                    <div className="faq_down_container">
                      <p style={{color:"#d9d9d9"}}>{item?.answer}</p>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="faq_rgt_container">
            <div className="faq_img_container">
              <img src={props.faqImg} alt="faqImg" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
