import React, { useState } from "react";
// import { MdClose, MdMenu } from "react-icons/md";
import image1 from "../../assets/image1.png";
import "./hero.css";
import Carousel from "./Carousel";
import "./Carousel.css";
import Navbar from "../Navbar";
import { Link } from "react-router-dom";

const HeroSection = () => {
  const images = [
    "https://img.freepik.com/free-vector/hand-painted-watercolor-pastel-sky-background_23-2148902771.jpg",
    "https://i.pinimg.com/736x/62/b8/92/62b8921486642e19163d9b5503b5784b.jpg",
    "https://img.freepik.com/free-vector/hand-painted-watercolor-pastel-sky-background_23-2148902771.jpg",

    // Add more image URLs as needed
  ];
  return (
    <div className="hero_section" style={{ backgroundImage: `url(${image1})` }}>
      <div
        className="hero_header same_width_container "
        style={{ margin: "0 auto" }}
      >
        <Navbar />
      </div>
      <div className="same_width_container">
      <div className="hero_content ">
        <h1 className="hero_title">
          Maximize Heat, <br/><span className="highlight">Minimize</span> Risk
        </h1>
        {/* <h1 className="hero_title mb-4">
          <span className="highlight">Expert</span> Chimney Sweeping
        </h1> */}
        <p className="hero_tag">
          A SAFER CHIMNEY DOES NOT HAPPEN BY CHANCE, IT <br/>HAPPENS BY APPOINTMENT!
        </p>
        <Link  to='/ContactUspage'>
        <button  className="hero_button">Book Now</button>
        </Link>
      </div>
      </div>
      
      {/* carousel */}

      {/* <div className="carousel_main_container">
        <Carousel images={images} height_fix="height_fix" />
      </div> */}
    </div>
  );
};

export default HeroSection;
